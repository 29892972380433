import { useEffect, useState } from 'react'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { pingAlive } from '../../hooks/instacart/pingAlive'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { reportLocation } from '../../hooks/instacart/reportLocation'
import { getActiveBatch } from '../../hooks/instacart/getActiveBatch'
import DriveEtaRoundedIcon from '@mui/icons-material/DriveEtaRounded'
import ShoppingBasketRoundedIcon from '@mui/icons-material/ShoppingBasketRounded'
import { NeuDiv } from 'neumorphism-react'
import { colors } from '../../colors'
import { Button } from '@mui/material'
import { Chip } from 'ui-neumorphism'
import { ContentCopyRounded } from '@mui/icons-material'
import { startBatch } from '../../hooks/instacart/startBatch'
import { unauth } from '../../hooks/instacart/unauth'
import { AcceptedBatchInfoCard } from './helpers/AcceptedBatchInfoCard'
import googleMapsIcon from '../../icons/googleMapsIcon.png'
import './batchfinderActive.css'
import { BackButton } from '../../components/BackButton'
import { CustomLoader } from './helpers/CustomLoader'

export const BatchFinderActive = ({
  global,
  currentLicense,
  maintenance,
  sessionId,
  accountId,
}) => {
  const { saveSessionId, handleLogOut, handleShowSnackbar, showAlert } = global
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [loading, setLoading] = useState(false)
  const [startingBatch, _startingBatch] = useState(false)
  const [activeBatch, setActiveBatch] = useState()
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [lastHeartbeatTime, setLastHeartbeatTime] = useState(0)
  const [lastGetLocationTime, setLastGetLocationTime] = useState(0)
  const [lastReportLocationTime, setLastReportLocationTime] = useState(0)
  const [batchStarted, setBatchStarted] = useState(false)

  const handlePingAlive = async () => {
    const res = await pingAlive(sessionId)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else if (res.body === 'Missing account profiles') {
        navigate({
          pathname: '/batchfinder/identity',
          search: searchParams.toString(),
        })
      } else if (res.body === 'Not allowed to go online') {
        showAlert(
          'Error Going Online',
          `Not allowed to go online. Please contact @BatchFinderHelp on Telegram.\n\n${accountId}`,
          'client_error',
        )
      }
    } else if (res.statusCode === 200) {
    } else if (res.statusCode === 0) {
    } else {
      alert('Error pinging alive.')
    }
  }

  const handleReportLocation = async () => {
    // const res = await reportLocation(sessionId, latitude, longitude)
    const res = await reportLocation(
      sessionId,
      activeBatch.waypoint.latitude,
      activeBatch.waypoint.longitude,
    )
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      }
    } else if (res.statusCode === 200) {
    } else if (res.statusCode === 0) {
    } else {
      alert('Error updating position:', JSON.stringify(res))
    }
  }

  const handleGetActiveBatch = async () => {
    const res = await getActiveBatch(sessionId)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      }
    } else if (res.statusCode === 200) {
      if (!res.body?.batch) {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else {
        setActiveBatch(res.body.batch)
      }
      // setActiveBatch({
      //   earnings: 38.67,
      //   tip: 13.72,
      //   items: 33,
      //   units: 47,
      //   customers: 3,
      //   waypoint: {
      //     address: '111 E El Camino Real. Sunnyvale, CA 94087, USA',
      //     street: '111 E El Camino Real',
      //     latitude: 37.3675293,
      //     longitude: -122.0302196,
      //   },
      //   distance: 10.1,
      // })
    } else if (res.statusCode === 0) {
    } else {
      alert('Error retrieving active batch:', JSON.stringify(res))
    }
  }

  const handleStartBatch = async () => {
    _startingBatch(true)
    const res = await startBatch(sessionId)
    _startingBatch(false)
    if (res.statusCode === 400) {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else if (res.body === 'No active batch') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else {
        alert(
          'Oops, please try again. If you continue to see this message, please contact @BatchFinderHelp on Telegram',
        )
      }
    } else if (res.statusCode === 200) {
      setBatchStarted(true)
    } else {
      alert(
        'Oops, please try again. If you continue to see this message, please contact @BatchFinderHelp on Telegram',
      )
    }
  }

  const handleTransferBatch = async () => {
    setLoading(true)
    const unauthRes = await unauth(sessionId)
    setLoading(false)
    setBatchStarted(true)
  }

  useEffect(() => {
    if (sessionId && !lastHeartbeatTime) {
      const temp = async () => {
        await handlePingAlive()
        setLastHeartbeatTime(new Date().getTime())
      }
      temp()
    }
  })

  useEffect(() => {
    if (sessionId && lastHeartbeatTime && !activeBatch) {
      handleGetActiveBatch()
    }
  })

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (new Date().getTime() > lastGetLocationTime + 10000 && !batchStarted) {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(function (position) {
            if (position === undefined) {
              alert('Location not enabled')
              navigate({ pathname: '/app', search: searchParams.toString() })
            } else {
              setLatitude(position.coords.latitude)
              setLongitude(position.coords.longitude)
              setLastGetLocationTime(new Date().getTime())
            }
          })
        } else {
          alert('Location not enabled')
          navigate({ pathname: '/app', search: searchParams.toString() })
        }
      }
    }, 1000)
    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (new Date().getTime() > lastHeartbeatTime + 30000 && !batchStarted) {
        await handlePingAlive()
        setLastHeartbeatTime(new Date().getTime())
      }
    }, 1000)
    return () => clearInterval(intervalId)
  })

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (
        sessionId &&
        lastHeartbeatTime &&
        // latitude &&
        // longitude &&
        activeBatch &&
        !batchStarted &&
        new Date().getTime() > lastReportLocationTime + 10000
      ) {
        await handleReportLocation()
        setLastReportLocationTime(new Date().getTime())
      }
    }, 1000)
    return () => clearInterval(intervalId)
  })

  const openInGoogleMaps = () => {
    window.open(
      `https://maps.google.com?q=${activeBatch.waypoint.latitude},${activeBatch.waypoint.longitude}`,
    )
  }

  const handleCopyAddress = () => {
    handleShowSnackbar('Address')
    navigator.clipboard.writeText(activeBatch.waypoint.address)
  }

  return (
    <div class="noselect">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {loading && <LoadingSpinner />}
        {startingBatch && <CustomLoader />}
        {!batchStarted && (
          <h1
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
              fontWeight: 800,
              fontSize: '40px',
              color: '#05062d',
            }}
          >
            Batch Accepted
          </h1>
        )}
        {batchStarted && (
          <h1
            style={{
              paddingTop: '20px',
              paddingBottom: '20px',
            }}
          >
            Batch Started
          </h1>
        )}
        {activeBatch && !batchStarted && (
          <AcceptedBatchInfoCard batch={activeBatch} />
        )}
        {activeBatch && !batchStarted && (
          <>
            <p
              style={{
                fontSize: '34px',
                fontWeight: 800,
                paddingTop: '30px',
                paddingBottom: '5px',
                color: '#05062d',
              }}
            >
              Drive To Store
            </p>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100vw',
                justifyContent: 'space-around',
              }}
            >
              <button
                class="buttonShiny"
                onClick={handleCopyAddress}
                style={{
                  marginTop: '5px',
                  animation: '0.2s ease-in 0s 1 popAppear',
                }}
              >
                <span class="text" style={{ padding: '15px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {activeBatch.waypoint.street}{' '}
                    <ContentCopyRounded style={{ marginLeft: '10px' }} />
                  </div>
                </span>
              </button>
              <button
                class="buttonShiny"
                onClick={openInGoogleMaps}
                style={{
                  marginTop: '5px',
                  animation: '0.2s ease-in 0s 1 popAppear',
                }}
              >
                <span class="text" style={{ padding: '6px' }}>
                  <img
                    src={googleMapsIcon}
                    alt="googleMaps"
                    style={{
                      height: '40px',
                      marginLeft: '4px',
                    }}
                  />
                </span>
              </button>
            </div>
            <p
              style={{
                fontSize: '30px',
                fontWeight: 800,
                paddingTop: '30px',
                paddingBottom: '5px',
                color: '#05062d',
              }}
            >
              When You Arrive
            </p>
            <p
              style={{
                paddingBottom: '20px',
              }}
            >
              Click below to start the batch
            </p>
            <button
              class="buttonShiny"
              onClick={handleStartBatch}
              style={{ animation: '0.2s ease-in 0s 1 popAppear' }}
            >
              <span class="text" style={{ padding: '15px', fontWeight: 500 }}>
                Start Shopping
              </span>
            </button>
            <p
              style={{
                textAlign: 'center',
                paddingTop: '10px',
              }}
            >
              Then sign into the Shopper app
            </p>
          </>
        )}
        {/* {activeBatch && !batchStarted && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                flexWrap: 'wrap',
                width: '100vw',
                justifyContent: 'space-around',
              }}
            >
              <button
                class="buttonShiny"
                onClick={handleCopyAddress}
                style={{
                  marginTop: '5px',
                  animation: '0.2s ease-in 0s 1 popAppear',
                }}
              >
                <span class="text" style={{ padding: '15px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {activeBatch.waypoint.street}{' '}
                    <ContentCopyRounded style={{ marginLeft: '10px' }} />
                  </div>
                </span>
              </button>
              <button
                class="buttonShiny"
                onClick={openInGoogleMaps}
                style={{
                  marginTop: '5px',
                  animation: '0.2s ease-in 0s 1 popAppear',
                }}
              >
                <span class="text" style={{ padding: '6px' }}>
                  <img
                    src={googleMapsIcon}
                    alt="googleMaps"
                    style={{
                      height: '40px',
                      marginLeft: '4px',
                    }}
                  />
                </span>
              </button>
            </div>
            <p
              style={{
                marginTop: '20px',
                fontSize: '20px',
                fontWeight: 600,
              }}
            >
              Click below to transfer the
            </p>
            <p
              style={{
                paddingBottom: '20px',
                fontSize: '20px',
                fontWeight: 600,
              }}
            >
              batch to your Shopper account
            </p>
            <button
              class="buttonShiny"
              onClick={handleTransferBatch}
              style={{ animation: '0.2s ease-in 0s 1 popAppear' }}
            >
              <span class="text" style={{ padding: '15px', fontWeight: 500 }}>
                Transfer Batch
              </span>
            </button>
            <p
              style={{
                textAlign: 'center',
                paddingTop: '20px',
                fontWeight: 600,
              }}
            >
              Then sign into the Shopper app
            </p>
          </>
        )} */}
        {batchStarted && (
          <div
            style={{
              width: '80%',
              display: 'flex',
              flexDirection: 'column',
              paddingTop: '50px',
            }}
          >
            <p
              style={{
                color: colors.green,
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '20px',
              }}
            >
              Batch started successfully!
            </p>
            <p
              style={{
                textAlign: 'center',
                paddingTop: '30px',
              }}
            >
              Sign into the Shopper app to begin shopping for the batch.
            </p>
          </div>
        )}
      </div>
      <BackButton />
    </div>
  )
}
