import { useNavigate, useSearchParams } from 'react-router-dom'
import { Button } from '@mui/material'
import { startBatchFinderService } from '../../../../hooks/startBatchFinderService'
import { goOnline } from '../../../../hooks/goOnline'
import { verifyUser } from '../../../../hooks/verifyUser'
import { ProgressCircular } from 'ui-neumorphism'
import { colors } from '../../../../colors'

export const StartTurbo = ({
  global,
  sessionId,
  accountId,
  _turboRunning,
  currentLicense,
  locationLatitude,
  locationLongitude,
  handleGetServiceSettings,
  showBackgrounding,
  _showBackgrounding,
}) => {
  const { handleLogOut, showAlert, setIsLoading } = global

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const handleStartTurbo = async () => {
    setIsLoading(true)
    const res = await startBatchFinderService(sessionId, 'turbo')
    setIsLoading(false)
    if (res.statusCode === 200) {
      handleGetServiceSettings(true)
      _turboRunning(true)
    } else if (res.statusCode === 0) {
    } else {
      if (res.body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (res.body === 'Location not provided') {
        showAlert(
          'Missing Location Settings',
          'Make sure to choose your location settings before starting BatchFinder',
          'client_error',
        )
      } else if (res.body === 'Already running') {
        _turboRunning(true)
      } else {
        showAlert(
          'Error starting BatchFinder',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    }
  }

  const verifyUserHandler = async () => {
    setIsLoading(true)
    const res = await verifyUser(sessionId)
    setIsLoading(false)
    const { statusCode, body } = res
    if (statusCode === 200) {
      await new Promise((resolve) => setTimeout(resolve, 3000))
      return onclick()
    }
    if (body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (body === 'Invalid Instacart credentials') {
      navigate({
        pathname: '/batchfinder',
        search: searchParams.toString(),
      })
    } else if (body === 'Missing account profiles') {
      navigate({
        pathname: '/batchfinder/identity',
        search: searchParams.toString(),
      })
    } else {
      showAlert(
        'Error going online',
        `Not allowed to go online. Please contact @BatchFinderHelp on Telegram.\n\n${accountId}`,
        'client_error',
      )
    }
  }

  const onClick = async () => {
    if (showBackgrounding) {
      _showBackgrounding(false)
      _turboRunning(true)
      return
    }

    setIsLoading(true)
    const res = await goOnline(sessionId)
    setIsLoading(false)
    const { statusCode, body } = res
    if (statusCode === 200) {
      await handleStartTurbo()
    } else if (statusCode === 0) {
    } else {
      if (body === 'Invalid session') {
        navigate({ pathname: '/app', search: searchParams.toString() })
        handleLogOut()
      } else if (body === 'Invalid Instacart credentials') {
        navigate({ pathname: '/batchfinder', search: searchParams.toString() })
      } else if (body === 'Verification required') {
        verifyUserHandler()
      } else if (body === 'Not allowed to go online') {
        showAlert(
          'Error going online',
          `Not allowed to go online. Please contact @BatchFinderHelp on Telegram.\n\n${accountId}`,
          'client_error',
        )
      } else {
        showAlert(
          'Error starting BatchFinder',
          `Raw Error: ${JSON.stringify(res)}`,
          'client_error',
        )
      }
    }
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Button
        variant="contained"
        color={showBackgrounding ? 'primary' : 'success'}
        sx={{ marginTop: '20px', fontSize: '25px' }}
        onClick={onClick}
        disabled={!currentLicense || !locationLatitude || !locationLongitude}
      >
        {showBackgrounding ? 'VIEW TURBO' : 'START TURBO'}
        {showBackgrounding && (
          <span style={{ fontSize: '0.6em', marginLeft: '5px' }}>
            <ProgressCircular
              indeterminate
              size={24}
              width={2}
              color="var(--primary)"
            />
          </span>
        )}
      </Button>
      {showBackgrounding && (
        <p
          style={{
            color: colors.black,
            textAlign: 'center',
            padding: '5px 25px 0px 25px',
            fontSize: '0.8em',
            fontWeight: 400,
          }}
        >
          BatchFinder is currently running. Click the button above to view
          batches.
        </p>
      )}
    </div>
  )
}
