import { useEffect, useState } from 'react'
import { getLicense } from '../../hooks/getLicense'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../../colors'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { assignTrialLicense } from '../../hooks/assignTrialLicense'
import { CurrentLicense } from '../../components/CurrentLicense'
import { BackButton } from '../../components/BackButton'
import { assignGeneralLicense } from '../../hooks/assignGeneralLicense'
import { LicenseHeader } from './components/LicenseHeader'
import { PurchaseOptions } from './components/PurchaseOptions'
import { Button } from '@mui/material'
import { Basic } from './components/Basic'
import { Premium } from './components/Premium'
import { Turbo } from './components/Turbo'
import { StandardCard } from './StandardCard'
import { PremiumCard } from './PremiumCard'
import { TurboCard } from './TurboCard'

const handleGetLicensePricing = (config, variant, length) => {
  if (!config) return
  if (variant === 'turbo') {
    switch (length) {
      case 30:
        return config.thirty_day_turbo.value
      case 7:
        return config.seven_day_turbo.value
      case 1:
        return config.one_day_turbo.value
      default:
        return 'N/A'
    }
  } else if (variant === 'premium') {
    switch (length) {
      case 30:
        return config.thirty_day_premium.value
      case 7:
        return config.seven_day_premium.value
      case 1:
        return config.one_day_premium.value
      default:
        return 'N/A'
    }
  } else if (variant === 'standard') {
    switch (length) {
      case 30:
        return config.thirty_day.value
      case 7:
        return config.seven_day.value
      case 1:
        return config.one_day.value
      default:
        return 'N/A'
    }
  } else {
    return 'N/A'
  }
}

const handleGetLicenseLink = (variant, length) => {
  if (![1, 30, 7].includes(length)) return '.'
  if (!['turbo', 'premium', 'standard'].includes(variant)) return '.'
  const link_name = variant === 'standard' ? '' : '_' + variant

  return `./license${link_name}_${length}`
}

export const LicenseInfo = ({
  pricingConfig,
  setCurrentLicense,
  setLicenseUse,
  global,
  currentLicense,
  sessionId,
  licenseUse,
}) => {
  const { saveSessionId, handleLogOut, showAlert } = global
  const [loading, setLoading] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)
  const [displayLicenseType, _displayLicenseType] = useState()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  const handleGetCurrentLicense = async () => {
    setLoading(true)
    const res = await getLicense(sessionId)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (res.statusCode === 200) {
      // setCurrentLicense(undefined)
      setCurrentLicense(res.body.license)
      setLicenseUse(res.body.license_use)
    } else if (res.statusCode === 404) {
      setCurrentLicense(undefined)
      setLicenseUse(res.body.license_use)
    } else {
      console.log('Error retrieving license:', res)
    }
    setLoading(false)
  }

  const activateTrialLicense = async () => {
    setLoading(true)
    const res = await assignTrialLicense(sessionId)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      saveSessionId('')
      navigate({ pathname: '/app', search: searchParams.toString() })
    } else if (res.statusCode !== 200) {
      alert(
        'Error activating trial license. Please contact @BatchFinderHelp on Telegram. Error log:',
        res.body,
      )
    } else {
      showAlert(
        'Trial License Activated!',
        'Your trial license has been successfully activated. If this is your first time using BatchFinder, I recommend reading through the Setup Guide on the main BatchFinder page.',
        'success',
      )
      await handleGetCurrentLicense()
    }
    setLoading(false)
  }

  const activateGeneralLicense = async (licenseId, licenseDisplayName) => {
    setLoading(true)
    const res = await assignGeneralLicense(sessionId, licenseId)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      saveSessionId('')
      navigate({ pathname: '/app', search: searchParams.toString() })
    } else if (res.statusCode === 200) {
      showAlert(`${licenseDisplayName} License Activated!`, 'Enjoy!', 'success')
      await handleGetCurrentLicense()
    } else {
      alert(
        `Error activating ${licenseDisplayName} license. Please contact @BatchFinderHelp on Telegram. Error log: ${res.body}`,
      )
    }
    setLoading(false)
  }

  useEffect(() => {
    if (currentLicense === undefined && sessionId && firstLoad) {
      handleGetCurrentLicense()
      setFirstLoad(false)
    }
  })

  return (
    <div
      class="noselect"
      style={{
        backgroundColor: colors.splashDark,
        minHeight: '100vh',
        fontFamily: "'Plus Jakarta Sans', sans-serif",
      }}
    >
      {loading && <LoadingSpinner />}
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: '1rem',
        }}
      >
        {!displayLicenseType && <LicenseHeader />}
        {currentLicense && !displayLicenseType && (
          <>
            <CurrentLicense currentLicense={currentLicense} />
            <p
              style={{
                color: colors.white,
                fontWeight: 800,
                fontSize: '1.2em',
                margin: '3rem 0 1rem 0',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() =>
                _displayLicenseType(currentLicense.license_variant)
              }
            >
              Extend License
            </p>
            {currentLicense.license_variant === 'turbo' && (
              <Turbo
                handleGetLicensePricing={handleGetLicensePricing}
                handleGetLicenseLink={handleGetLicenseLink}
                pricingConfig={pricingConfig}
                onClick={() => _displayLicenseType('turbo')}
              />
            )}
            {currentLicense.license_variant === 'premium' && (
              <Premium
                handleGetLicensePricing={handleGetLicensePricing}
                handleGetLicenseLink={handleGetLicenseLink}
                pricingConfig={pricingConfig}
                onClick={() => _displayLicenseType('premium')}
              />
            )}
            {currentLicense.license_variant === 'standard' && (
              <Basic
                handleGetLicensePricing={handleGetLicensePricing}
                handleGetLicenseLink={handleGetLicenseLink}
                pricingConfig={pricingConfig}
                onClick={() => _displayLicenseType('standard')}
              />
            )}
          </>
        )}
        {/* {!displayLicensePricing &&
          licenseUse?.returning_promo_nov_2023 === false && (
            <div
              style={{
                marginTop: '2rem',
                padding: '0.5rem 1rem',
                fontWeight: 800,
                borderRadius: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                backgroundColor: colors.splashGreen,
                color: colors.splashDark,
                border: 'none',
                transition: 'transform 0.1s, filter 0.1s',
                textDecoration: 'none',
              }}
              onTouchStart={(e) => {
                e.currentTarget.style.transform = 'scale(0.95)'
              }}
              onTouchEnd={(e) => {
                e.currentTarget.style.transform = 'scale(1)'
              }}
              onClick={() => {
                activateGeneralLicense(
                  'returning_promo_nov_2023',
                  'Returning Promo License',
                )
              }}
            >
              <h3>Free 3 Day License</h3>
              <p
                style={{
                  fontWeight: 500,
                  fontSize: '0.8em',
                  paddingTop: '5px',
                }}
              >
                Thanks for coming back!
              </p>
            </div>
          )} */}

        {!currentLicense && !displayLicenseType && (
          <PurchaseOptions _displayLicenseType={_displayLicenseType} />
        )}
        {displayLicenseType === 'turbo' && (
          <TurboCard
            handleGetLicensePricing={handleGetLicensePricing}
            handleGetLicenseLink={handleGetLicenseLink}
            pricingConfig={pricingConfig}
          />
        )}
        {displayLicenseType === 'premium' && (
          <PremiumCard
            handleGetLicensePricing={handleGetLicensePricing}
            handleGetLicenseLink={handleGetLicenseLink}
            pricingConfig={pricingConfig}
          />
        )}
        {displayLicenseType === 'standard' && (
          <StandardCard
            handleGetLicensePricing={handleGetLicensePricing}
            handleGetLicenseLink={handleGetLicenseLink}
            pricingConfig={pricingConfig}
          />
        )}
        <Button
          variant="contained"
          sx={{ position: 'absolute', bottom: '20px', left: '20px' }}
          onClick={() => {
            if (!displayLicenseType)
              navigate({
                pathname: '/app',
                search: searchParams.toString(),
              })
            _displayLicenseType()
          }}
        >
          Back
        </Button>
      </div>
    </div>
  )
}
