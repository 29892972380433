import { colors } from '../../colors'
import onboardingSplash from './batchfinder_onboarding.png'

export const OnboardingFinal = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: colors.splashDark,
        color: colors.white,
        padding: '50px 20px 20px 20px',
        textAlign: 'center',
      }}
    >
      <h1
        style={{ fontSize: '1.8rem', fontWeight: 500, paddingBottom: '1rem' }}
        className="slide-in"
      >
        Ready to Get Started?
      </h1>
      <div
        style={{
          width: '90%',
          backgroundColor: '#404040',
          padding: '20px 10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px',
          margin: '2rem 0',
        }}
        className="slide-in"
      >
        <h3 style={{ paddingBottom: '1rem' }}>
          About the Developer of BatchFinder
        </h3>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'left',
            paddingLeft: '1rem',
          }}
        >
          <p
            style={{
              fontSize: '15px',
              paddingBottom: '15px',
            }}
          >
            <b>&#8226;</b> 15+ years of development experience
          </p>
          <p
            style={{
              fontSize: '15px',
              paddingBottom: '15px',
            }}
          >
            <b>&#8226;</b> Free 4-day trial licenses for new users
          </p>
          <p
            style={{
              fontSize: '15px',
              paddingBottom: '15px',
            }}
          >
            <b>&#8226;</b> Well-established reputation in the bot community
          </p>
          <p
            style={{
              fontSize: '15px',
              paddingBottom: '15px',
            }}
          >
            <b>&#8226;</b> BatchFinder has been available for 3+ years
          </p>
          <p
            style={{
              fontSize: '15px',
              paddingBottom: '15px',
            }}
          >
            <b>&#8226;</b> 0 users deactivated since release
          </p>
        </div>

        <b style={{ padding: '0.5rem 0' }}>Questions?</b>
        <p>
          Contact me on Telegram{' '}
          <span
            style={{
              color: '#3DADFF',
              fontWeight: 600,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() =>
              window.open('https://t.me/BatchFinderHelp', '_blank')
            }
          >
            @BatchFinderHelp
          </span>
          <br />
          <br />
          I'll respond within 15 minutes!
        </p>
      </div>
    </div>
  )
}
