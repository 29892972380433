import { colors } from '../../colors'
import instacartEmail from './instacart_email.png'

export const Onboarding_5 = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100vw',
        height: '100vh',
        backgroundColor: colors.splashDark,
        color: colors.white,
        padding: '50px 20px 20px 20px',
        textAlign: 'center',
      }}
    >
      <h1
        style={{ fontSize: '1.8rem', fontWeight: 500, padding: '0 0 2rem 0' }}
        className="slide-in"
      >
        Ignore Fake Warning
        <br />
        Emails from Instacart
      </h1>
      <div
        style={{
          width: '90%',
          backgroundColor: '#404040',
          padding: '20px 10px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          borderRadius: '10px',
        }}
        className="slide-in"
      >
        <p>
          Instacart may send you a warning email about using "automated
          programs"
        </p>
        <b
          style={{
            padding: '0.5rem 0',
            fontSize: '1.1rem',
            color: '#3DADFF',
          }}
        >
          Do not worry if you get this email.
        </b>
        <div
          style={{
            width: '95%',
            padding: '0.5rem 0',
            borderRadius: '10px',
            overflow: 'clip',
          }}
        >
          <img
            src={instacartEmail}
            alt="instacartEmail"
            style={{
              width: '100%',
              height: 'auto',
              borderRadius: '10px',
            }}
          />
        </div>
        <p style={{ textAlign: 'left', padding: '0.2rem 0 0.2rem 8px' }}>
          This email is sent from an old automated system that Instacart{' '}
          <b>does not use anymore</b>. It sends this email to any Shopper who
          signs in and out frequently.
        </p>
        <b style={{ color: colors.splashGreen, padding: '0.5rem 0rem' }}>
          This is completely safe.
        </b>
        <p>Questions?</p>
        <p>
          You can ask{' '}
          <span
            style={{
              color: '#3DADFF',
              fontWeight: 600,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() =>
              window.open('https://t.me/BatchFinderHelp', '_blank')
            }
          >
            The Dev
          </span>{' '}
          or{' '}
          <span
            style={{
              color: '#3DADFF',
              fontWeight: 600,
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() =>
              window.open('https://t.me/+IOe5ap0cjYo1OTUx', '_blank')
            }
          >
            Trusted Users
          </span>
          .
        </p>
      </div>
    </div>
  )
}
