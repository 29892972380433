import { NeuButton } from 'neumorphism-react'
import { useState } from 'react'
import AuthCode from 'react-auth-code-input'
import { colors } from '../../colors'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { requestVerificationCode } from '../../hooks/instacart/requestVerificationCode'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { verifyVerificationCode } from '../../hooks/instacart/verifyVerificationCode'
import { Button } from '@mui/material'
import { BackButton } from '../../components/BackButton'

export const BatchFinderSetup = ({
  global,
  currentLicense,
  maintenance,
  sessionId,
  accountId,
}) => {
  const { handleLogOut, showAlert } = global
  const [waitingForVerificationCode, setWaitingForVerificationCode] =
    useState(false)
  const [verificationCode, setVerificationCode] = useState('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const handleRequestVerificationCode = async () => {
    setLoading(true)
    const res = await requestVerificationCode(sessionId)
    if (res.body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (res.body === 'License not found') {
      navigate({ pathname: '/batchfinder', search: searchParams.toString() })
    } else if (res.body === 'Rate limited') {
      showAlert(
        'Slow Down!',
        `You have requested a code too many times. Please wait 2 minutes before trying again.\n\n${accountId}`,
        'client_error',
      )
    } else if (res.statusCode === 200) {
      setWaitingForVerificationCode(true)
    } else {
      showAlert(
        'Error Sending Code',
        `Sorry about that, BatchFinder ran into an issue. Raw Error: ${JSON.stringify(
          res,
        )}\n\n${accountId}`,
        'client_error',
      )
    }
    setLoading(false)
  }

  const handleVerifyVerificationCode = async () => {
    setLoading(true)
    const res = await verifyVerificationCode(sessionId, verificationCode)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (res.statusCode === 400 && res.body === 'Incorrect code') {
      setVerificationCode('')
      showAlert(
        'Incorrect Code',
        'The code you entered was incorrect. Please try again.',
        'user_error',
      )
    } else if (res.statusCode === 400 && res.body === 'License not found') {
      navigate({ pathname: '/batchfinder', search: searchParams.toString() })
    } else if (res.statusCode === 200) {
      navigate({ pathname: '/batchfinder', search: searchParams.toString() })
    } else {
      showAlert(
        'Error Verifying Code',
        `Sorry about that, BatchFinder ran into an issue checking the code. Raw Error: ${JSON.stringify(
          res,
        )}`,
        'client_error',
      )
    }
    setLoading(false)
  }

  return (
    <div class="noselect">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {loading && <LoadingSpinner />}
        <h1
          style={{
            paddingTop: '20px',
            paddingBottom: '20px',
          }}
        >
          BatchFinder Setup
        </h1>
        {!waitingForVerificationCode && (
          <p
            style={{
              textAlign: 'center',
              padding: '0px 40px 20px 40px',
              fontWeight: 500,
            }}
          >
            Please log out of the Shopper app before linking your account here.
          </p>
        )}
        {/* <p
          style={{
            color: colors.doordash_red,
            textAlign: 'center',
            fontWeight: 500,
            padding: '20px 40px',
          }}
        >
          There is an issue with the account linking service. I am working on a
          fix.
        </p> */}
        {!waitingForVerificationCode && (
          <Button
            variant="contained"
            style={{
              margin: '10px 0px',
            }}
            onClick={handleRequestVerificationCode}
          >
            Link Account
          </Button>
        )}
        {!waitingForVerificationCode && (
          <>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '20px 20px 0px 20px',
              }}
            >
              <p
                style={{
                  fontSize: '20px',
                  fontWeight: 'bold',
                  textAlign: 'center',
                  paddingBottom: '20px',
                }}
              >
                My promise as a developer:
              </p>
              <p
                style={{
                  fontSize: '15px',
                  paddingBottom: '10px',
                }}
              >
                <b>&#8226;</b> BatchFinder can only view and accept batches
              </p>
              <p
                style={{
                  fontSize: '15px',
                  paddingBottom: '10px',
                }}
              >
                <b>&#8226;</b> BatchFinder <b>will never</b> access anything
                else
              </p>
              <p
                style={{
                  fontSize: '15px',
                  paddingBottom: '10px',
                }}
              >
                <b>&#8226;</b> Account data is deleted immediately after use
              </p>
              <p
                style={{
                  fontSize: '15px',
                  paddingBottom: '10px',
                }}
              >
                <b>&#8226;</b> All BatchFinder features are extensively tested
              </p>
              <p
                style={{
                  fontSize: '15px',
                  paddingBottom: '10px',
                }}
              >
                <b>&#8226;</b> BatchFinder has been <b>undetectable</b> for 2+
                years
              </p>
              <p
                style={{
                  fontSize: '15px',
                  paddingBottom: '10px',
                }}
              >
                <b>&#8226;</b> BatchFinder has been <b>working</b> for 2+ years
              </p>
            </div>
            <p
              style={{
                padding: '20px',
                fontSize: '14px',
              }}
            >
              Your account's security is my priority as a developer and I have
              worked constantly to uphold this for the past 2 years.
            </p>
            <p
              style={{
                padding: '0px 20px',
                fontSize: '14px',
              }}
            >
              If you have any questions or concerns, please contact me on
              Telegram @BatchFinderHelp
            </p>
            <p
              style={{
                paddingTop: '20px',
                fontSize: '16px',
              }}
            >
              Your Developer,<br></br>Avalon (BatchFinderHelp)
            </p>
          </>
        )}
        {waitingForVerificationCode && (
          <>
            <h2
              style={{
                fontSize: '20px',
                fontWeight: 500,
                color: colors.black,
                marginBottom: '10px',
              }}
            >
              Click box to enter code below
            </h2>
            <AuthCode
              allowedCharacters="numeric"
              onChange={setVerificationCode}
              inputClassName="input"
            />
          </>
        )}
        {verificationCode.length === 6 && (
          <NeuButton
            width="60vw"
            height="30px"
            style={{
              marginTop: '30px',
              fontSize: '16px',
              color: colors.black,
              margin: '10px 0px',
            }}
            color={colors.offWhite}
            distance={2}
            onClick={handleVerifyVerificationCode}
          >
            Click to Submit
          </NeuButton>
        )}
        <BackButton />
      </div>
    </div>
  )
}
