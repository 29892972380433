// import styles from './styles.module.css'
import { NeuButton, NeuDiv } from 'neumorphism-react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { colors } from '../../colors'

//AIDOC: Component shown when user completes a license purchase
export const PaymentComplete = () => {
  const location = useLocation()
  const order = location.state?.order
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  return (
    <NeuDiv
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '95vw',
        padding: '10px 15px 10px 15px',
        marginTop: '20px',
        color: colors.black,
      }}
      color={colors.offWhite}
    >
      <h1
        class="noselect"
        style={{
          fontSize: '26px',
          fontWeight: 600,
          marginTop: '10px',
        }}
      >
        Purchase confirmed
      </h1>
      <p
        style={{
          fontSize: '20px',
          fontWeight: 400,
          marginTop: '10px',
        }}
      >
        <b>Order ID: </b> {order?.id}
      </p>
      <b
        style={{
          fontSize: '16px',
          marginTop: '10px',
        }}
      >
        Thank you for purchasing a BatchFinder license.
      </b>
      <p
        style={{
          fontSize: '16px',
          fontWeight: 400,
          marginTop: '10px',
        }}
      >
        Your license key has been added to your account.
      </p>
      <p
        style={{
          fontSize: '16px',
          fontWeight: 400,
          marginTop: '10px',
        }}
      >
        If you had any issues purchasing the license key or while using the app,
        please contact{' '}
        <a href="https://t.me/BatchFinderHelp" target="_blank">
          @BatchFinderHelp
        </a>{' '}
        on Telegram.
      </p>
      <NeuButton
        width="80vw"
        height="40px"
        style={{
          marginTop: '30px',
          fontSize: '20px',
          fontWeight: 400,
          color: colors.black,
          margin: '10px 0px',
        }}
        color={colors.offWhite}
        onClick={() =>
          navigate({ pathname: '/app', search: searchParams.toString() })
        }
        distance={2}
      >
        Main Menu
      </NeuButton>
    </NeuDiv>
  )
}
