import { useEffect, useState } from 'react'
import { colors } from '../../colors'
import { LoadingSpinner } from '../../components/LoadingSpinner'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Buffer } from 'buffer'
import { Button } from '@mui/material'
import './BatchFinderSelfie.css'
import centerProfileExample from '../../icons/centerProfileSelfie.png'
import leftProfileExample from '../../icons/leftProfileSelfie.png'
import rightProfileExample from '../../icons/rightProfileSelfie.png'
import { updateIdentity } from '../../hooks/updateIdentity'
import { Card, Dialog } from 'ui-neumorphism'
import { BackButton } from '../../components/BackButton'

const getErrorGuidance = (error) => {
  switch (error) {
    case 'Error decoding image':
      return 'The image could not be processed. Please try taking a new photo with better lighting.'
    case 'No face detected':
      return 'No face was detected in the photo. Please ensure your face is clearly visible and well-lit.'
    case 'Right overrotation detected':
      return 'Your face is turned too far to the right. Please turn your head slightly less.'
    case 'Left overrotation detected':
      return 'Your face is turned too far to the left. Please turn your head slightly less.'
    case 'Underrotation detected':
      return 'Your head is not turned enough. Please turn your head a bit more.'
    case 'Face out of bounds':
      return 'Your face is too close to the edge of the frame. Please center yourself in the photo.'
    case 'Bad face orientation':
      return 'Your face orientation does not match the required direction. Please check the example image.'
    default:
      return 'Please try taking the photo again following the example image.'
  }
}

const formatErrorMessage = (errors) => {
  let message = 'Please correct the following issues:\n\n'

  if (errors.left) {
    message += `Left photo: ${getErrorGuidance(errors.left)}\n\n`
  }
  if (errors.center) {
    message += `Center photo: ${getErrorGuidance(errors.center)}\n\n`
  }
  if (errors.right) {
    message += `Right photo: ${getErrorGuidance(errors.right)}\n\n`
  }

  return message
}

const UploadBox = ({ name, _preview, preview, tip, example }) => {
  const handleFileChange = (event, _preview) => {
    const uploadedFile = event.target.files[0]
    if (!uploadedFile) return
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()
      img.onload = () => {
        const targetWidth = 752
        const targetHeight = 1000
        const targetAspectRatio = targetWidth / targetHeight

        let imgWidth = img.width
        let imgHeight = img.height
        const imgAspectRatio = imgWidth / imgHeight

        let sx, sy, sWidth, sHeight

        if (imgAspectRatio > targetAspectRatio) {
          sHeight = imgHeight
          sWidth = imgHeight * targetAspectRatio
          sx = (imgWidth - sWidth) / 2
          sy = 0
        } else if (imgAspectRatio < targetAspectRatio) {
          sWidth = imgWidth
          sHeight = imgWidth / targetAspectRatio
          sx = 0
          sy = (imgHeight - sHeight) / 2
        } else {
          sx = 0
          sy = 0
          sWidth = imgWidth
          sHeight = imgHeight
        }

        const canvas = document.createElement('canvas')
        canvas.width = targetWidth
        canvas.height = targetHeight
        const ctx = canvas.getContext('2d')
        ctx.drawImage(
          img,
          sx,
          sy,
          sWidth,
          sHeight,
          0,
          0,
          targetWidth,
          targetHeight,
        )

        let jpegUrl
        for (let quality = 0.8; quality >= 0.3; quality -= 0.05) {
          jpegUrl = canvas.toDataURL('image/jpeg', quality)
          const base64Str = jpegUrl.split(',')[1]
          const binaryData = Buffer.from(base64Str, 'base64')
          if (binaryData.length < 70 * 1024) break
        }

        _preview(jpegUrl)
      }
      img.src = e.target.result
    }
    reader.readAsDataURL(uploadedFile)
  }

  return (
    <div
      style={{
        minHeight: '8rem',
        width: '80vw',
        borderRadius: '8px',
        padding: '10px',
        margin: '10px 0px',
        border: '2px solid',
        borderColor: colors.black,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <b>{tip}</b>
      <div className="file-input">
        <input
          type="file"
          id={name}
          className="file"
          accept="image/*"
          onChange={(e) => handleFileChange(e, _preview)}
        />
        <label htmlFor={name}>Upload {name} selfie</label>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'space-between',
          padding: '0px 20px',
        }}
      >
        {!preview && (
          <div
            style={{
              width: '75px',
              height: '100px',
              backgroundColor: colors.gray300,
            }}
          />
        )}
        {preview && (
          <img
            src={preview}
            alt="Preview"
            style={{ width: 'auto', maxHeight: '100px' }}
          />
        )}
        <img
          src={example}
          alt="Example"
          style={{
            width: 'auto',
            height: '100px',
            padding: '0px 20px',
          }}
        />
      </div>
    </div>
  )
}

export const BatchFinderSelfie = ({
  global,
  currentLicense,
  maintenance,
  sessionId,
  isLoggedIn,
}) => {
  const { handleLogOut, showAlert } = global
  const [loading, _loading] = useState(false)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [leftPreview, _leftPreview] = useState()
  const [rightPreview, _rightPreview] = useState()
  const [centerPreview, _centerPreview] = useState()
  const [showIntro, _showIntro] = useState(true)

  const handleSubmitPictures = async () => {
    if (!leftPreview || !rightPreview || !centerPreview) {
      showAlert('Please upload all 3 selfies')
      return
    }
    _loading(true)
    const res = await updateIdentity(
      sessionId,
      leftPreview,
      centerPreview,
      rightPreview,
    )
    _loading(false)
    if (res.statusCode === 400 && res.body === 'Invalid session') {
      navigate({ pathname: '/app', search: searchParams.toString() })
      handleLogOut()
    } else if (res.statusCode === 200) {
      alert('Successfully uploaded pictures. Please start the bot now.')
      navigate({ pathname: '/batchfinder', search: searchParams.toString() })
    } else {
      _leftPreview()
      _centerPreview()
      _rightPreview()

      try {
        if (res.body.errors) {
          alert(formatErrorMessage(res.body.errors))
        } else if (res.body.err === 'duplicate selfie') {
          alert('You have already used these pictures. Please take new ones.')
        } else {
          alert('There was an error uploading your pictures. Please try again.')
        }
      } catch (e) {
        alert('There was an error uploading your pictures. Please try again.')
      }
    }
  }

  return (
    <div class="noselect">
      <Dialog visible={showIntro} onClose={() => _showIntro(false)}>
        <Card
          style={{
            justifyContent: 'center',
            padding: '10px 0px',
            width: '90vw',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: '0px 20px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '22px',
                  color: colors.black,
                  paddingBottom: '15px',
                  fontWeight: 500,
                }}
              >
                What's This?
              </p>
              <p
                style={{
                  textAlign: 'left',
                  fontSize: '18px',
                  color: colors.black,
                  whiteSpace: 'pre-line',
                }}
              >
                Unfortunately, Instacart recently deploy a security change. To
                bypass it, BatchFinder requires the following information. If
                you have any questions, please contact @BatchFinderHelp on
                Telegram.
              </p>
            </div>
            <Button
              variant="contained"
              sx={{ marginTop: '20px' }}
              onClick={() => _showIntro(false)}
            >
              Get Started
            </Button>
            <p
              style={{
                textAlign: 'flex-start',
                fontSize: '14px',
                color: colors.black,
                marginTop: '20px',
              }}
            >
              Questions? Contact{' '}
              <a
                href="https://t.me/BatchFinderHelp"
                target="_blank"
                style={{ fontWeight: 600 }}
              >
                @BatchFinderHelp
              </a>{' '}
              on Telegram
            </p>
          </div>
        </Card>
      </Dialog>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {loading && <LoadingSpinner />}
        <p
          style={{
            textAlign: 'center',
            padding: '10px 0px 10px 0px',
            fontWeight: 600,
            fontSize: '1.2rem',
          }}
        >
          Verify identity for Instacart
        </p>
        <UploadBox
          name="LEFT"
          _preview={_leftPreview}
          preview={leftPreview}
          tip="Upload a selfie looking to YOUR left"
          example={leftProfileExample}
        />
        <UploadBox
          name="CENTER"
          _preview={_centerPreview}
          preview={centerPreview}
          tip="Upload a selfie looking straight"
          example={centerProfileExample}
        />
        <UploadBox
          name="RIGHT"
          _preview={_rightPreview}
          preview={rightPreview}
          tip="Upload a selfie looking to YOUR right"
          example={rightProfileExample}
        />
        <Button
          variant="contained"
          style={{
            margin: '10px 0px',
            position: 'absolute',
            bottom: '0px',
            left: '50%',
            transform: 'translateX(-50%)',
          }}
          onClick={handleSubmitPictures}
        >
          Submit
        </Button>
        <BackButton />
      </div>
    </div>
  )
}
